import { ref, onMounted, unref, watch, computed } from 'vue';

import { buildURLParams } from '@base/utils/build-url-params';
import { axios } from '@/plugins';
import { useRoute } from '@base/hooks/useRouter';

export const useFetch = (url, { autoFilter = false } = {}) => {
  const route = useRoute();

  const data = ref();
  const isFetching = ref(true);
  const error = ref();

  const searchParams = computed(() => new URLSearchParams(buildURLParams(route.query)));

  const fetchData = async () => {
    isFetching.value = true;

    try {
      const compoundUrl = `${unref(url)}${autoFilter ? `?${searchParams.value.toString()}` : ''}`;
      const { data: res } = await axios.get(compoundUrl);

      data.value = res;
    } catch (e) {
      error.value = e;
    } finally {
      isFetching.value = false;
    }
  };

  onMounted(fetchData);

  if (autoFilter) watch(() => route.query, fetchData);

  return {
    data,
    isFetching,
    error,
    query: searchParams,
    refresh: fetchData,
  };
};
